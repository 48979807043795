import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons'
import './accordion.scss'

const Accordion = ({ index, title, content }) => {
  const [isActive, setIsActive] = useState(false)
  return (
    <div className="tabs">
      <div className="tab">
        <input type="checkbox" id={`chck${index}`} className="hide-checkbox" />
        <label className="tab-label" htmlFor={`chck${index}`}>
          <strong>{title ?? 'No Title'}</strong>
        </label>
        <div className="tab-content">
          <span>{content ?? 'No Content'}</span>
        </div>
      </div>
    </div>
  )
}

export default Accordion
