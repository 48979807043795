import React from 'react'
import Footer from '../../components/Footer'
import Hero from '../../components/Hero/Hero'
import InfoCard from '../../components/InfoCard/InfoCard'
import SampleSizeCTA from '../../Contentful/SampleSize'
import { QueryGraphql } from '../../utils/graphQlQuery'
import './solutions.scss'

const Solutions = () => {
  let data = QueryGraphql()
  const {
    allContentfulSolution: { nodes: solutionData },
    allContentfulHero: { nodes },
  } = data

  // const { solutionsType, titleAndContent } = solutionData

  const solutionsType = solutionData.map((val, ind) => {
    return val.solutionsType
  })
  const titleAndContent = solutionData.map((val, ind) => {
    return val.titleAndContent
  })

  console.log({ solutionsType })
  const mainData = nodes
    .filter((val) => val.header === 'Akta Solutions')
    .filter((val) => {
      const { desc: mainDesc } = val
      return mainDesc
    })

  const results = mainData[0]
  // console.log({ results })

  const { header, mainDescription, title: info, backgroundColor } = results

  const fullDescription = mainDescription.map((val, ind) => {
    let description = {}
    description.info = val
    return description
  })
  // const pageDescription = mainDescription.join('')

  /*   const { desc: mainDesc } = desc[0]

  console.log({ mainDesc }) */

  // let solutionsType = ['Data', 'Tech', 'Marketing']

  return (
    <div>
      <Hero
        description={fullDescription}
        title={info}
        backgroundColor={backgroundColor}
      />
      <InfoCard
        solutionData={solutionData}
        titleAndContent={titleAndContent}

        solutionTypes={solutionsType}
      />
      <SampleSizeCTA buttonTitle={'Create Survey'} />
      <Footer />
    </div>
  )
}

export default Solutions
