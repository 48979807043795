import { Link } from 'gatsby'
import React, { useState } from 'react'
import CTA from '../CTA'
import TeamWork1 from '../../images/team_work.png'
import TeamWork2 from '../../images/team_work_2.jpg'
import TeamWork3 from '../../images/team_work_3.jpg'
import './infocard.scss'
import Accordion from '../Accordion/Accordion'

const InfoCard = ({ solutionTypes, solutionData, titleAndContent }) => {
  const [active, setActive] = useState('data')

  const cm = (...args) => {
    return args.filter((v) => v).join(' ')
  }

  const toggleButtonFunction = (buttonStatus) => {
    setActive(buttonStatus)
  }
  return (
    <div className="info-card-container">
      <div className="row jt-cn mt-5">
        {solutionTypes &&
          solutionTypes.map((type, index) => (
            <Link to="#0" className="btn-large" key={index}>
              <CTA
                key={index}
                width="100%"
                height="30px"
                size="1em"
                type={`${cm(
                  '',
                  active !== type.toLowerCase()
                    ? 'primary-transparent'
                    : 'primary'
                )}`}
                onClick={() => toggleButtonFunction(type.toLowerCase())}
              >
                {type}
              </CTA>
            </Link>
          ))}
      </div>

      <div>
        {solutionData &&
          solutionData.map(({ solutionsType, titleAndContent }, index) => (
            <div
              key={index}
              className={`row sp-ev p-2 ${cm(
                '',
                active !== solutionsType.toLowerCase() ? 'hidden' : ''
              )}`}
            >
              <div className={`col ht-100 w-35 w-mb-35`}>
                {titleAndContent &&
                  titleAndContent.map((val, index) => (
                    <div
                      data-inde-attr={`d` + index + solutionsType.toLowerCase()}
                    >
                      <Accordion
                        index={`d` + index + solutionsType.toLowerCase()}
                        title={val.title}
                        content={val.content}
                      />
                    </div>
                  ))}
              </div>
              <div className="col ">
                <img
                  src={TeamWork1}
                  alt="team_work_1"
                  width={'550px'}
                  className="fl-rt br-4 shdw solution-images"
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default InfoCard
